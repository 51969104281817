// 운영 서비스
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&family=Open+Sans:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');
// @import url('/static/font/OpenSans/opensans.css');
// @import url('/static/font/Noto_Sans_KR/notosans.css');
@font-face {
	font-family: "Material Icons";
	font-style: normal;
	font-weight: 400;
	src: url('/static/font/MaterialIcons/MaterialIcons-Regular.woff') format("woff2"), 
		 url('/static/font/MaterialIcons/MaterialIcons-Regular.woff') format("woff");
}
@font-face {
	font-family: "Material Icons Outlined";
	font-style: normal;
	font-weight: 400;
	src: url('/static/font/MaterialIcons/MaterialIconsOutlined-Regular.woff') format("woff2"), 
		 url('/static/font/MaterialIcons/MaterialIconsOutlined-Regular.woff') format("woff");
}
// @import url('https://fonts.googleapis.com/css2?family=Material+Icons');
// @import url('https://fonts.googleapis.com/css2?family=Material+Icons+Outlined');
// @import url('https://fonts.googleapis.com/css2?family=Material+Icons+Round');
// @import url('https://fonts.googleapis.com/css2?family=Material+Icons+Sharp');
// @import url('https://fonts.googleapis.com/css2?family=Material+Icons+Two+Tone');